
import { infinityScrollTable, helperMixin } from "@/mixins";
import { defineComponent } from "vue";
import BaseTable from "@/components/Base/BaseTable.vue";
import BaseTableRow from "@/components/Base/BaseTableRow.vue";
import BaseTableColumn from "@/components/Base/BaseTableColumn.vue";
import crewBoardHttp from "@/http/crewBoard";
import {
  SeaServicePaginate,
  SeaServicePaginateFilter
} from "@/models/crewBoard";

export default defineComponent({
  props: {
    userId: {
      required: true
    }
  },
  components: {
    BaseTable,
    BaseTableRow,
    BaseTableColumn
  },
  mixins: [
    helperMixin,
    infinityScrollTable<SeaServicePaginate, SeaServicePaginateFilter>(
      crewBoardHttp()
    )
  ],
  computed: {
    paginateMethodName() {
      return "historyByUser";
    }
  },
  methods: {
    onMounted() {
      this.filter.user_id = this.userId;
    }
  }
});
