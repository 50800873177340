import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_table_column = _resolveComponent("base-table-column")!
  const _component_base_table_row = _resolveComponent("base-table-row")!
  const _component_base_table = _resolveComponent("base-table")!

  return (_openBlock(), _createBlock(_component_base_table, {
    colspan: "7",
    onLoadMore: _ctx.loadMore,
    loading: _ctx.loading,
    hasMore: _ctx.hasMore,
    noDataAvailable: _ctx.noDataAvailable,
    noResultFound: _ctx.noResultFound,
    type: "border"
  }, {
    header: _withCtx(() => [
      _createVNode(_component_base_table_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_base_table_column, { type: "label" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.trans("layout.fleet.ship_name")), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_base_table_column, { type: "label" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.trans("layout.job.position_id")), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_base_table_column, { type: "label" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.trans("layout.crew.sign_on")), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_base_table_column, { type: "label" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.trans("layout.crew.sign_off")), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_base_table_column, { type: "label" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.trans("layout.fleet.type_of_ship")), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_base_table_column, { type: "label" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.trans("layout.fleet.dwt_summer_draft")), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_base_table_column, { type: "label" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.trans("layout.crew.company")), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.rows, (row, i) => {
        return (_openBlock(), _createBlock(_component_base_table_row, { key: i }, {
          default: _withCtx(() => [
            _createVNode(_component_base_table_column, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(row.ship_name), 1)
              ]),
              _: 2
            }, 1024),
            _createVNode(_component_base_table_column, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(row.position_name), 1)
              ]),
              _: 2
            }, 1024),
            _createVNode(_component_base_table_column, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.formatDate(row.sign_on)), 1)
              ]),
              _: 2
            }, 1024),
            _createVNode(_component_base_table_column, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.formatDate(row.sign_off)), 1)
              ]),
              _: 2
            }, 1024),
            _createVNode(_component_base_table_column, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.formatDate(row.type_of_ship)), 1)
              ]),
              _: 2
            }, 1024),
            _createVNode(_component_base_table_column, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.formatDate(row.dwt_summer_draft)), 1)
              ]),
              _: 2
            }, 1024),
            _createVNode(_component_base_table_column, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.formatDate(row.company_name)), 1)
              ]),
              _: 2
            }, 1024)
          ]),
          _: 2
        }, 1024))
      }), 128))
    ]),
    _: 1
  }, 8, ["onLoadMore", "loading", "hasMore", "noDataAvailable", "noResultFound"]))
}