
import AvatarImg from "@/components/AvatarImg.vue";
import BaseButtonDanger from "@/components/Base/BaseButtonDanger.vue";
import BaseButtonDefault from "@/components/Base/BaseButtonDefault.vue";
import BaseButtonPrimary from "@/components/Base/BaseButtonPrimary.vue";
import TeleportToContentFooter from "@/components/TeleportToContentFooter.vue";
import { crewStatus, helperMixin, userProfilePDF } from "@/mixins";
import { CrewDetail } from "@/models/crew";
import { defineComponent } from "vue";
import crewHttp from "@/http/crew";
import BaseBadge from "@/components/Base/BaseBadge.vue";
import SeaService from "@/components/Crew/SeaService.vue";
import AddToBoardModal from "@/components/Crew/AddToBoardModal.vue";
import UserCertificatesDownload from "@/components/JobApplicant/UserCertificatesDownload.vue";
import CertificateDetail from "@/components/Crew/CertificateDetail.vue";
import { toast } from "@/template/app";

export default defineComponent({
  components: {
    AvatarImg,
    TeleportToContentFooter,
    BaseButtonDanger,
    BaseButtonPrimary,
    BaseButtonDefault,
    BaseBadge,
    SeaService,
    AddToBoardModal,
    UserCertificatesDownload,
    CertificateDetail,
  },
  mixins: [helperMixin, crewStatus, userProfilePDF],
  data() {
    return {
      showAddToBoardModal: false,
      crew: null as any,
    };
  },
  computed: {
    crewHttp,
    pdfFilename() {
      if (!this.crew) return ""
      return this.crew?.jobApplicant?.user?.fullname || "";
    },
  },
  mounted() {
    this.fetch();
  },
  methods: {
    async fetch() {
      const { status, message, response } = await this.crewHttp.read(
        this.currentRoute.params?.id
      );

      if (status === 200) {
        this.crew = response.data;
      } else if (this.currentRoute.meta?.rootName) {
        this.useRouter().push({
          name: this.currentRoute.meta?.rootName,
        });
      } else {
      }
    },
  },
});
